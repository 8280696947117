<template>
  <div class="mobile__menu" :class="{ 'active' : mobileMenu}" v-scroll-lock="mobileMenu">
    <div class="main">
      <div class="top">
        <svg @click="setMobileMenu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="close__btn">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11L4.00001 3L3.29291 3.70711L11.2929 11.7071L3 20L3.70711 20.7071L12 12.4142L20.2635 20.6777L20.9706 19.9706L12.7071 11.7071L20.6777 3.73655L19.9706 3.02944L12 11Z" fill="#000001"/>
        </svg>
        <div class="right">
          <template v-if="!isLogin">
            <div class="login" @click="goToLogin">{{$t('header.login')}}</div>
            <div class="register" @click="goToRegister">{{$t('header.registration')}}</div>
          </template>
          <div v-else class="login aush" @click="goToDashboard">{{ $t('header.dashboard') }}</div>
        </div>
      </div>
      <div class="links" ref="linksMobile">
        <router-link to="/category">{{$t('header.courses')}}</router-link>
        <router-link 
          v-if="isLogin && checkCountry && checkUserType"
          to="/shop-category"
        >
          {{ $t('header.shop') }}
        </router-link>
        <a class="shop-lock"
         v-else
         style="cursor:pointer; color: rgba(81, 80, 118, 0.7)"
         :data-title="(isLogin && !checkCountry && !checkUserType) ?  $t('header.shopClose') : $t('header.shopHidden')"
        >
          {{ $t('header.shop') }}
          <svg class="icon-lock" width="15px" height="15px" fill="rgba(81, 80, 118, 0.7)">
            <use xlink:href="/icons.svg#lock"></use>
          </svg>
        </a>
        <!-- <router-link to="/specialists">{{$t('header.specialists')}}</router-link> -->
        <router-link to="/about">{{$t('header.about')}}</router-link>
        <router-link to="/blog">{{$t('header.blog')}}</router-link>
        <router-link :to="{name: 'promotions', params: {lang : langStorage} }">{{$t('header.promotions')}}</router-link>
      </div>
    </div>
    <!-- <div class="lang">
      <div>
        <a @click="selectLang(index), langChange()" :class="{'active' : lang.status }" v-for="(lang, index) in langs" :key="lang.l">{{ lang.l }}</a>
      </div>
      <div>
        <svg class="sun" :style="{'opacity': !darkMode ? 1 : 0}" @click="setDarkMode(true)">
          <use xlink:href="/icons.svg#sun"></use>
        </svg>
        <svg class="moon" :style="{'opacity': darkMode ? 1 : 0}" @click="setDarkMode(false)">
          <use xlink:href="/icons.svg#moon"></use>
        </svg>
      </div>
    </div> -->
  </div>
</template>

<script>
import LangRu from '@/lang/components/header/ru' 
import LangRo from '@/lang/components/header/ro' 
import LangEng from '@/lang/components/header/eng' 
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  mixins:[LangRu, LangRo, LangEng],
  data:() => ({
    langs: [
      { l : 'Ro' , status: false, lStore: 'ro' },
      { l : 'Ru', status: false, lStore: 'ru' },
      { l : 'Eng', status: false, lStore: 'en'},
      { l : 'Fr', status: false, lStore: 'fr'},
      { l : 'Es', status: false, lStore: 'es' }
    ]
  }),
  watch: {
    $route (to, from){
     this.changeMobileMenu(false);
    }
  },
  computed:{
    ...mapGetters({
      langStorage : 'languages/getLang',
      darkMode: 'darkMode',
      mobileMenu: 'mobileMenu',
      isLogin: 'user/isLogin',
      checkCountry: 'user/checkCountry',
      checkUserType: 'user/checkUserType',
    }),
    selectLng() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    },
  },
  methods: {
    langChange(){
      let  lang = localStorage.getItem('lang');
      this.$i18n.locale = lang
    },
    ...mapMutations({changeMobileMenu: 'changeMobileMenu'}),
    ...mapActions({
      setLang: 'languages/setLang',
      setDarkMode: 'setDarkMode',
      setMobileMenu: 'setMobileMenu'
    }),
    selectLang(ind){
      this.langs.forEach(item => item.status = false);
      this.langs[ind].status = true;
      this.setLang(this.langs[ind].lStore);
      this.langChange();
      if(this.$route.params.lang != this.langs[ind].lStore) {
        this.$router.push({name: this.$route.name, params: {lang: this.langs[ind].lStore}})
      }
      //this.setMobileMenu();
    },
    goToLogin(){
      this.$router.push({name: 'login', params: {lang: this.langStorage}});
      this.setMobileMenu();
    },
    goToRegister(){
      this.$router.push({name: 'Registration', params: {lang: this.langStorage}});
      this.setMobileMenu();
    },
    goToDashboard(){
      this.$router.push({name: 'courses', params: {lang: this.langStorage}});
      this.setMobileMenu();
    }
  },
  mounted(){
    // this.$refs.linksMobile.childNodes.forEach((link) => {
    //   link.addEventListener('click', () => {
    //     this.setMobileMenu();
    //   })
    // });

    this.langs.forEach(item => {
      if(this.langStorage == item.lStore) {
        item.status = true;
      }
    });
    
  }
}
</script>

<style lang="scss" scoped>
  .mobile__menu {
    height: 100%;

    .lang {
      display: flex;
      justify-content: space-between;
      .moon,.sun {
        width: 25px;
        height: 25px;
        transition: 0.5s;
        fill: black;
      }
    }
  }
  .lang {
    transition: bottom .25s,opacity .25s;
    transition-delay: .3s;
    display: flex;
    -webkit-box-pack: justify;
    position: fixed;
    bottom: 0;
  }
  .mobile__menu {
    .shop-lock {
      display: block;;
      position: relative;

      &:hover::after {
        content: attr(data-title);
        position: absolute;
        background-color: #EFEEFD;
        width: 150px;
        padding: 8px;
        border-radius: 12px;
        font-size: 13px;
        line-height: normal;
        color: black;
        top: 95%;
        left: 0px;
        z-index: 1;
      }
    }
  }
</style>